import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ProgressiveImage from 'gatsby-image'

import Modal from 'components/Modal'
import Play from 'images/icons/play.inline.svg'
import Apps from 'images/icons/apps.inline.svg'
import OpenAI from 'images/icons/openai.inline.svg'
import OpenAIBadge from 'images/icons/openai-badge.inline.svg'

const Component = () => {
  const [isOpen, setOpen] = React.useState(false)

  const { image } = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "demo-screenshot@3x.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 860, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <>
      <Modal
        youtubeId="MvPj16SKUUE"
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />

      <div className="py-8 md:py-12">
        <div className="container">
          <div className="space-y-6 md:space-y-16">
            <h2 className="h2 text-center">How it works?</h2>

            <div className="grid md:grid-cols-3 xl:grid-cols-12 items-center gap-8">
              <div className="hidden xl:block" />

              <div className="md:col-span-2 xl:col-span-6">
                <div
                  role="button"
                  className="w-full relative group transition-all duration-300 transform-gpu hover:scale-105"
                  onClick={() => setOpen(true)}
                >
                  <div className="absolute top-1/2 left-1/2 transform-gpu -translate-x-1/2 -translate-y-1/2 w-16 h-16 md:w-32 md:h-32 transition duration-300 group-hover:scale-90 bg-indigo-600 text-white flex items-center justify-center rounded-full z-10">
                    <Play className="w-6 md:w-12 -mr-2" />
                  </div>
                  <ProgressiveImage fluid={image.childImageSharp.fluid} />
                </div>
              </div>

              <div className="xl:col-span-4 text-center md:text-left">
                <ul className="space-y-6 inline-block">
                  {[
                    'Describe your project',
                    'Choose the content you need',
                    'AI genereates your copy',
                  ].map((headline, index) => (
                    <li key={index} className="flex items-center space-x-4">
                      <div className="flex-shrink-0 flex items-center justify-center w-8 h-8 text-base bg-indigo-600 text-white rounded-full">
                        {index + 1}
                      </div>
                      <h3>{headline}</h3>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="grid xl:grid-cols-12 gap-8">
              <div className="hidden xl:block" />

              <div className="xl:col-span-10 grid md:grid-cols-2 border-t border-gray-200 divide-y md:divide-y-0 md:divide-x divide-gray-200">
                <div className="py-10 md:py-20 text-center">
                  <div className="max-w-xs mx-auto space-y-4">
                    <div className="flex items-center justify-center h-14">
                      <Apps />
                    </div>
                    <h3 className="h5">Platform Independent</h3>
                    <p>
                      cmd-v is a web-based platform, so you can paste the
                      generated copy to any design tool.
                    </p>
                  </div>
                </div>

                <div className="py-10 md:py-20 text-center">
                  <div className="max-w-xs mx-auto space-y-4">
                    <div className="flex items-center justify-center h-14">
                      <OpenAI />
                    </div>
                    <h3 className="h5">
                      <OpenAIBadge className="inline-block" />
                      <span className="sr-only">Powered by OpenAI</span>
                    </h3>
                    <p>
                      cmd-v is the first tool for designers based on <a href="https://en.wikipedia.org/wiki/GPT-3">GPT-3</a>, the
                      most powerful language AI-model.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Component
