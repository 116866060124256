import React from 'react'

import Tombstone from 'images/icons/tombstone.inline.svg'

const Component = () => (
  <div className="bg-black py-8 md:py-16 lg:py-24">
    <div className="container">
      <div className="max-w-2xl mx-auto text-white">
        <div className="flex flex-col md:flex-row items-center space-y-12 md:space-y-0 md:space-x-12">
          <div className="flex-grow-0 flex-shrink-0">
            <Tombstone />
          </div>

          <div className="flex-grow space-y-4">
            <h3 className="h2">R.I.P. Lorem Ipsum</h3>
            <p>
              Designing a website means finding a way to communicate a message
              in the most effective way. It’s hard to achive this goal without
              the copy — the message itself.
            </p>
            <p>
              The consensus on why Lorem Ipsum is bad for your design was
              achived years ago, but no real alternatives were created. Until
              now.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Component
