import React from 'react'

import Scroller from 'components/Scroller'

import Globe from 'images/icons/globe.inline.svg'
import Palette from 'images/icons/palette.inline.svg'

const Component = () => {
  return (
    <div className="py-8 md:py-12">
      <div className="container">
        <div className="space-y-6 md:space-y-16">
          <h2 className="h2 text-center">Generate Copy for:</h2>

          <div className="grid md:grid-cols-2 xl:grid-cols-12 gap-8">
            <div className="hidden xl:block" />

            <div className="relative xl:col-span-5 bg-gradient-to-b from-viola to-rose flex flex-col items-center py-12 md:py-20 px-8 space-y-8 rounded-3xl transition-all duration-300 transform-gpu hover:scale-105 hover:-translate-y-2 hover:shadow-xl">
              <div className="flex items-end justify-center h-16">
                <Globe className="w-14" />
              </div>

              <div className="text-center space-y-2">
                <h3 className="h3">Client Websites</h3>
                <p>Save time, provide better value to clients.</p>

                <Scroller
                  component="button"
                  targetId="#websites"
                  className="inline-lock text-indigo-600 underline cover-parent"
                >
                  Learn more ↓
                </Scroller>
              </div>
            </div>

            <div className="relative xl:col-span-5 bg-gradient-to-b from-viola to-rose flex flex-col items-center py-12 md:py-20 px-8 space-y-8 rounded-3xl transition-all duration-300 transform-gpu hover:scale-105 hover:-translate-y-2 hover:shadow-xl">
              <div className="flex items-end justify-center h-16">
                <Palette className="w-16" />
              </div>

              <div className="text-center space-y-2">
                <h3 className="h3">Your Portfolio</h3>
                <p>Finally, finish that portfolio update.</p>

                <Scroller
                  component="button"
                  targetId="#portfolio"
                  className="inline-lock text-indigo-600 underline cover-parent"
                >
                  Learn more ↓
                </Scroller>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Component
