import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ProgressiveImage from 'gatsby-image'

import Globe from 'images/icons/globe.inline.svg'

import CursorIcon from 'images/icons/cursor.inline.svg'
import RevisionsIcon from 'images/icons/revisions.inline.svg'
import MoneyIcon from 'images/icons/money.inline.svg'
import Checkmark from 'images/icons/checkmark.inline.svg'

const Component = () => {
  const { image } = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "website-screenshot@3x.png" }) {
          childImageSharp {
            fluid(maxWidth: 605, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <div id="websites" className="py-8 md:pb-16">
      <div className="hidden">
        <Checkmark id="checkmark" />
      </div>
      <div className="container">
        <div className="py-8 md:py-16 bg-gradient-to-b from-viola to-rose rounded-3xl space-y-10 md:space-y-16 overflow-hidden">
          <div className="flex flex-col items-center space-y-4">
            <p className="text-xs opacity-30 uppercase font-bold">
              Best for Freelancers and Agencies
            </p>

            <div className="flex items-center">
              <Globe className="w-8 sm:w-14 mr-3" />
              <h2 className="h2 text-center">Client Websites</h2>
            </div>
          </div>

          <div className="grid lg:grid-cols-2 xl:grid-cols-12 gap-8 px-6 sm:px-12 md:pr-0 xl:px-0">
            <div className="hidden xl:block" />

            <div className="xl:col-span-5">
              <div className="max-w-xl mx-auto">
                <ul className="space-y-12">
                  {benefits.map(({ icon, title, description }, index) => (
                    <li key={index} className="space-y-4">
                      <div className="flex space-x-6">
                        <div className="flex-grow-0 flex-shrink-0 pt-2 sm:w-auto">
                          {icon}
                        </div>

                        <div className="flex-grow">
                          <h3 className="h4">{title}</h3>
                          <p>{description}</p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="xl:col-span-6">
              <div>
                <ProgressiveImage
                  fluid={image.childImageSharp.fluid}
                  className="-mr-16 md:-mr-4"
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 xl:grid-cols-12 gap-8 px-6 sm:px-12 xl:px-0">
            <div className="hidden xl:block" />

            <div className="col-span-10 space-y-8">
              <div className="flex items-center">
                <h4 className="h6 pr-6">Copy for client websites:</h4>
                <div className="flex-grow border-t border-gray-200" />
              </div>

              <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                <div className="space-y-2">
                  <h5 className="text-xs opacity-30 font-bold uppercase">
                    Homepage
                  </h5>
                  <ul className="text-sm space-y-1">
                    {[
                      'Hero Headline',
                      'Hero Sub-Headline',
                      'Benefits',
                      'How It Works',
                      'Features',
                      'Lead Magnet',
                      'Audience',
                      'Testimonials',
                      'Closing Call-To-Action',
                    ].map((example, index) => (
                      <li key={index} className="flex items-center">
                        <svg className="mr-2" style={{ width: 10, height: 7 }}>
                          <use href="#checkmark" />
                        </svg>
                        {example}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="space-y-2">
                  <h5 className="text-xs opacity-30 font-bold uppercase">
                    About Page
                  </h5>
                  <ul className="text-sm space-y-1">
                    {[
                      'Mission',
                      'The "Why"',
                      'Team Intro',
                      'Team Bios',
                      'Our Investors',
                    ].map((example, index) => (
                      <li key={index} className="flex items-center">
                        <svg className="mr-2" style={{ width: 10, height: 7 }}>
                          <use href="#checkmark" />
                        </svg>
                        {example}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="space-y-2">
                  <h5 className="text-xs opacity-30 font-bold uppercase">
                    Careers Page
                  </h5>
                  <ul className="text-sm space-y-1">
                    {[
                      'Intro',
                      'Why Work for Us?',
                      'Values',
                      'Perks',
                      'Opened Positions',
                    ].map((example, index) => (
                      <li key={index} className="flex items-center">
                        <svg className="mr-2" style={{ width: 10, height: 7 }}>
                          <use href="#checkmark" />
                        </svg>
                        {example}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="space-y-2">
                  <h5 className="text-xs opacity-30 font-bold uppercase">
                    Other Pages
                  </h5>
                  <ul className="text-sm space-y-1">
                    {[
                      'Blog headlines',
                      'FAQs',
                      'Contact Us',
                      'and more...',
                    ].map((example, index) => (
                      <li key={index} className="flex items-center">
                        <svg className="mr-2" style={{ width: 10, height: 7 }}>
                          <use href="#checkmark" />
                        </svg>
                        {example}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="hidden xl:block" />
          </div>
        </div>
      </div>
    </div>
  )
}

const benefits = [
  {
    icon: <CursorIcon className="w-8 h-8" />,
    title: 'Save Time',
    description:
      'Generate high quality copy in a matter of seconds. No more hours spent on mixing your competitors copy with your own copy ideas.',
  },
  {
    icon: <RevisionsIcon className="w-8 h-8" />,
    title: 'Have Less Revisions',
    description:
      'Having a good copy creates a better starting point for discussing your design. As a result — less comments, remarks and back-and-forths.',
  },
  {
    icon: <MoneyIcon className="w-8 h-8" />,
    title: 'Charge More',
    description:
      'Relevant and grammatically correct copy reqiures time and effort. Saving it to your clients increases the value of your work.',
  },
]

export default Component
