import React from 'react'

import { Button } from 'components/System'

const Component = () => (
  <div className="py-8 md:py-16">
    <div className="container">
      <div className="flex flex-col items-center text-center space-y-10">
        <div className="space-y-2">
          <h2 className="h1">Design Smarter, Not Harder</h2>
          <h3 className="text-lg">
            Put AI into work for your workflow and save time{' '}
          </h3>
        </div>

        <Button href="https://smoothlanding.typeform.com/to/N0p76po5">
          Apply for Beta Access
        </Button>
      </div>
    </div>
  </div>
)

export default Component
