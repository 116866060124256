import React from 'react'

import Layout from 'components/Layout'

import Hero from 'components/Sections/Hero'
import Topics from 'components/Sections/Topics'
// import Preview from 'components/Sections/Preview'
import RestInPeace from 'components/Sections/RestInPeace'
import HowItWorks from 'components/Sections/HowItWorks'
import ClientWebsites from 'components/Sections/ClientWebsites'
import Portfolio from 'components/Sections/Portfolio'
import Team from 'components/Sections/Team'
import OneMoreThing from 'components/Sections/OneMoreThing'
import CallToAction from 'components/Sections/CallToAction'

const Page = () => (
  <Layout>
    <Hero />
    <Topics />
    <HowItWorks />
    <ClientWebsites />
    <RestInPeace />
    <Portfolio />
    <Team />
    <OneMoreThing />
    <CallToAction />
    {/* <Preview />
     */}
  </Layout>
)

export default Page
