import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ProgressiveImage from 'gatsby-image'

import TwitterIcon from 'images/icons/twitter.inline.svg'

const Component = () => {
  const { artiom, martin } = useStaticQuery(
    graphql`
      query {
        artiom: file(relativePath: { eq: "team/artiom@3x.jpg" }) {
          childImageSharp {
            fixed(width: 108, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        martin: file(relativePath: { eq: "team/martin@3x.jpg" }) {
          childImageSharp {
            fixed(width: 108, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `
  )

  const team = [
    {
      image: artiom,
      name: 'Artiom Dashinsky',
      twitter: 'https://twitter.com/hvost',
      bio: (
        <>
          Designer, author and creator of numerous tools for designers:{' '}
          <a className="link" href="https://productdesigninterview.com/">
            Solving Product Design Exercises
          </a>
          ,{' '}
          <a className="link" href="https://sketchkeys.com/">
            SketchKeys
          </a>
          ,{' '}
          <a className="link" href="http://retinize.it/">
            Retinize It
          </a>
          , etc.
        </>
      ),
    },
    {
      image: martin,
      name: 'Martin Wessely',
      twitter: 'https://twitter.com/mrtnpro',
      bio: (
        <>
          Developer and designer. Previously lead developer at{' '}
          <a className="link" href="https://www.hellogustav.com/">
            Gustav
          </a>
          , co-founder of{' '}
          <a className="link" href="https://getbedtimestories.com/">
            Bedtime Stories
          </a>
          ,{' '}
          <a className="link" href="https://www.logggos.club/">
            Logggos
          </a>{' '}
          and{' '}
          <a className="link" href="https://besucherliste.at/">
            Besucherliste
          </a>
          .
        </>
      ),
    },
  ]

  return (
    <div className="py-8 md:py-16 lg:pt-24">
      <div className="container">
        <div className="space-y-16">
          <h2 className="h1 text-center">Built by Designers for Designers</h2>

          <div className="max-w-xl mx-auto">
            <ul className="space-y-12">
              {team.map(({ image, name, twitter, bio }, index) => (
                <li
                  key={index}
                  className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-8 text-center sm:text-left"
                >
                  <div className="flex-shrink-0">
                    <ProgressiveImage fixed={image.childImageSharp.fixed} />
                  </div>
                  <div className="flex-grow space-y-2 text-lg">
                    <div className="inline-flex items-center space-x-2">
                      <h3 className="h4 font-semibold">{name}</h3>
                      <a href={twitter}>
                        <span className="sr-only">{name} on Twitter</span>
                        <TwitterIcon />
                      </a>
                    </div>
                    <div>{typeof bio === 'string' ? <p>{bio}</p> : bio}</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Component
