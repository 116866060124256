import React from 'react'

import Coffee from 'images/icons/coffee.inline.svg'
import Grid from 'images/icons/grid.inline.svg'
import CrossOut from 'images/icons/cross-out.inline.svg'

const Component = () => {
  return (
    <div className="py-8 md:py-16">
      <div className="container">
        <div className="space-y-16">
          <div className="max-w-lg mx-auto text-center space-y-4">
            <h2 className="h2">
              <span className="inline-block relative">
                <CrossOut className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 scale-110 mt-0.5 text-indigo-600" />
                One
              </span>{' '}
              Two More Things
            </h2>
            <p>
              Our AI not only generates relevant human-level content but also
              comes up with new ideas for you.
            </p>
          </div>

          <div className="grid md:grid-cols-2 xl:grid-cols-12 gap-8">
            <div className="hidden xl:block" />

            <div className="xl:col-span-5 bg-gradient-to-b from-smoke to-peach flex flex-col items-center py-12 md:py-20 px-8 space-y-8 rounded-3xl">
              <div className="flex items-end justify-center h-24">
                <Coffee className="w-16" />
              </div>

              <div className="text-center space-y-2">
                <p className="text-xs uppercase font-bold opacity-30">
                  Generate
                </p>
                <h3 className="h3">Side Projects Ideas</h3>
                <p>
                  Tell cmd-v your interests and it will generate
                  ideas for side-projects you should build.
                </p>
              </div>
            </div>

            <div className="xl:col-span-5 bg-gradient-to-b from-smoke to-peach flex flex-col items-center py-12 md:py-20 px-8 space-y-8 rounded-3xl">
              <div className="flex items-end justify-center h-24">
                <Grid className="w-14" />
              </div>

              <div className="text-center space-y-2">
                <p className="text-xs uppercase font-bold opacity-30">
                  Generate
                </p>
                <h3 className="h3">Design Challenges</h3>
                <p>
                  cmd-v generates real-world problems that could be solved with
                  digital products. Use them to practice your skills or for your
                  portfolio.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Component
