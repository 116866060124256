import React from 'react'

import { SIGN_UP_URL } from 'utils'
import { Button } from 'components/System'

import CursorAI from 'images/icons/cursor-ai.inline.svg'

const Component = () => (
  <div className="py-8 md:py-16 lg:py-24">
    <div className="container">
      <div className="grid xl:grid-cols-12 gap-8 items-center">
        <div className="col-span-8 xl:col-span-8 xl:col-start-3">
          <div className="flex flex-col items-center text-center space-y-10">
            <div className="overflow-hidden md:overflow-visible space-y-6">
              <div className="-mx-2">
                <h1 className="h0">
                  Generate Real Copy <br />
                  <span className="relative inline-block whitespace-nowrap">
                    for Your Design
                    <CursorAI className="absolute left-full top-full -ml-2 -mt-7 lg:-ml-4 lg:-mt-12 w-12 lg:w-28" />
                  </span>
                </h1>
              </div>
              <h2 className="text-3xl">
                Save time and design better websites.
              </h2>
            </div>

            <Button href={SIGN_UP_URL}>Apply for Beta Access</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Component
