import React from 'react'
import { Transition } from '@headlessui/react'
import { useLockBodyScroll, useKey } from 'react-use'

export default function Modal({
  youtubeId,
  isOpen = false,
  onClose = () => {},
}) {
  useKey('Escape', onClose)
  useLockBodyScroll(isOpen)

  return (
    <Transition show={isOpen}>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center md:p-0">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="fixed inset-0 transition-opacity cursor-pointer"
            aria-hidden="true"
            onClick={onClose}
          >
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </Transition.Child>

          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          <span
            className="inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            className="inline-block bg-black rounded-lg shadow-xl transform transition-all w-full sm:my-8 align-middle sm:max-w-2xl lg:max-w-4xl xl:max-w-7xl"
            role="dialog"
            aria-modal="true"
          >
            <div className="absolute top-0 right-0 -mt-12 md:mt-0 md:-mr-12">
              <button
                onClick={onClose}
                className="flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              >
                <span className="sr-only">Close video</span>
                <svg
                  className="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
              <iframe
                width="600"
                title="cmd + v demo"
                src={`//www.youtube.com/embed/${youtubeId}?mute=0&amp;autoplay=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen=""
                tabIndex="-1"
              />
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition>
  )
}
