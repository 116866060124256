import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ProgressiveImage from 'gatsby-image'

import Palette from 'images/icons/palette.inline.svg'
import Checkmark from 'images/icons/checkmark.inline.svg'

const Component = () => {
  const { image } = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "portfolio-screenshot@3x.png" }) {
          childImageSharp {
            fluid(maxWidth: 605, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <div id="portfolio" className="pt-8 md:pt-16">
      <div className="hidden">
        <Checkmark id="checkmark" />
      </div>
      <div className="container">
        <div className="pt-8 md:pt-16 bg-gradient-to-b from-viola to-rose rounded-3xl space-y-6 md:space-y-16 overflow-hidden">
          <div className="flex items-center justify-center">
            <Palette className="w-14 mr-3" />
            <h2 className="h2 text-center">UX Portfolio</h2>
          </div>

          <div className="grid lg:grid-cols-5 xl:grid-cols-12 gap-8 px-6 sm:px-12 md:pr-0 xl:px-0">
            <div className="hidden xl:block" />

            <div className="lg:col-span-2 xl:col-span-4 space-y-8">
              <h4 className="h6 pr-6">Generate copy for your portfolio:</h4>

              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-8">
                <div className="space-y-2">
                  <h5 className="text-xs opacity-30 font-bold uppercase">
                    Pages
                  </h5>
                  <ul className="text-sm space-y-1">
                    {[
                      'Home',
                      'About',
                      'Case Study',
                      'Projects',
                      'Services',
                    ].map((example, index) => (
                      <li key={index} className="flex items-center">
                        <svg className="mr-2" style={{ width: 10, height: 7 }}>
                          <use href="#checkmark" />
                        </svg>
                        {example}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="space-y-2">
                  <h5 className="text-xs opacity-30 font-bold uppercase">
                    Caste study content
                  </h5>
                  <ul className="text-sm space-y-1">
                    {[
                      'The Challenge',
                      'KPIs',
                      'Solution',
                      'Research',
                      'Competitive analysis',
                      'Prototyping',
                      'Testing',
                      'Success metrics',
                      'Results',
                    ].map((example, index) => (
                      <li key={index} className="flex items-center">
                        <svg className="mr-2" style={{ width: 10, height: 7 }}>
                          <use href="#checkmark" />
                        </svg>
                        {example}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="hidden xl:block" />

            <div className="lg:col-span-3 xl:col-span-6">
              <div>
                <ProgressiveImage
                  fluid={image.childImageSharp.fluid}
                  className="-mr-16 md:-mr-4 -mb-4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Component
